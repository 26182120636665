<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-between align-center mb-2">
      <b-button
        class="ml-1"
        variant="outline-secondary"
        size="sm"
        @click="onClickReturn()"
      >
        <b-icon icon="arrow-left"></b-icon> volver
      </b-button>
      <div class="d-flex justify-content-center align-center editpos_group_btn">
        <b-button
          class="mx-1"
          variant="success"
          size="sm"
          @click="onClickNavButtons('router_editposdata_edit')"
        >
          Datos PDV
        </b-button>
        <b-button
          class="mx-1"
          variant="primary"
          size="sm"
          @click="onClickNavButtons('router_editposdata_geolocation')"
        >
          Cambio GPS
        </b-button>
        <b-button
          class="mx-1"
          size="sm"
          @click="onClickNavButtons('router_editposdata_visitday')"
        >
          Cambio Dia Visita
        </b-button>
        <b-button
          class="mx-1"
          variant="danger"
          size="sm"
          @click="onClickNavButtons('router_editposdata_inactive')"
        >
          Desactivar
        </b-button>
        <b-button
          class="mx-1"
          variant="primary"
          size="sm"
          @click="onClickNavButtons('router_editposdata_changeroute')"
        >
          Cambio Ruta
        </b-button>
      </div>
      <div></div>
    </div>
    <div class="border d-flex justify-content-center align-center mt-2 mb-2">
      <template v-if="currentPosData">
        <span class="trencadis-title my-3"
          >[{{ currentPosData.code || currentPosData.id }}]
          {{ currentPosData.name }}</span
        >
      </template>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { mapGetters } from "vuex";
import moment from "moment";
import { logInfo, logError } from "@/utils";
export default {
  name: "posdata",
  data() {
    return {
      sessionId: null,
      worksessionPosId: null
    };
  },
  computed: {
    ...mapGetters("auth", ["isSPV", "isGPV", "user"]),
    ...mapGetters("myroute", ["currentPosData"]),
    fromPageCategory() {
      const { frompage } = this.$route.params;
      return frompage;
    }
  },
  methods: {
    onClickReturn() {
      this.$router.push({
        name: "router_worksessionpospage",
        params: {
          id: this.sessionId,
          worksessionPosId: this.worksessionPosId,
          frompage: this.fromPageCategory
        }
      });
    },
    onClickNavButtons(routerName) {
      this.$router.push({
        name: routerName,
        params: {
          id: this.sessionId,
          worksessionPosId: this.worksessionPosId,
          frompage: this.fromPageCategory
        }
      });
    },
    async init() {
      const { id, worksessionPosId } = this.$route.params;
      this.sessionId = id;
      this.worksessionPosId = worksessionPosId;
      await this.$store.dispatch("myroute/getCurrentPosData", {
        sessionId: this.sessionId,
        worksessionPosId: this.worksessionPosId
      });
    }
  },
  async mounted() {
    await this.init();
  }
};
</script>

<style>
.editpos_group_btn .btn {
  color: #fff !important;
}
</style>
